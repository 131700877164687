import { Col, Row, Statistic } from 'antd'
import React from 'react'
import { useEffect, useState } from 'react';

function Stats() {
    const [result, setResult] = useState({
        sumDogenano: 0,
        sumDogecoin: 0
    })
    const [xdgNanoPrice, setXdgNanoPrice] = useState(0)
    const [nanoPrice, setNanoPrice] = useState(0)

    useEffect(() => {
        fetch('https://api.dogenano.io'+ '/get-total-claim', {method: 'GET', headers: { 'Accept': 'application/json'}})
          .then((r) => r.json())
          .then(r => {
            setResult(r[0])
          })
          
        fetch("https://data.nanswap.com/nano-price")
          .then((r) => r.json())
          .then(r => {
            setNanoPrice(r)
          })
        fetch("https://data.nanswap.com/get-markets")
          .then((r) => r.json())
          .then(r => {
            if (r[2].key === "XDG/XNO"){ // todo nanswap: index market by key
                setXdgNanoPrice(r[2].midPrice)
            }
          })
          
      }, [])

  return (
    <div >
    <Row gutter={{xs: 8, sm: 8}} >
        <Col span={12}>
        <Statistic style={{backgroundColor: "white", borderRadius: 8, marginRight: "auto", marginLeft: "auto", padding: 8, marginBottom: 16}} suffix={"XDG"} value={result.sumDogenano} title={"DogeNano claimed"} precision={0}/>
        </Col> 
        <Col span={12}>
        <Statistic style={{backgroundColor: "white", borderRadius: 8, marginRight: "auto", marginLeft: "auto", padding: 8, marginBottom: 16}} suffix={"XDG"} value={result.sumDogenano* 1.1} title={"Circulating Supply"} precision={0}/>
        </Col>
        <Col span={12}>
        <Statistic style={{backgroundColor: "white", borderRadius: 8, marginRight: "auto", marginLeft: "auto", padding: 8, marginBottom: 16}} suffix={"$"} value={xdgNanoPrice*nanoPrice} title={"DogeNano price (Nanswap)"} precision={6}/>
        </Col>
        <Col span={12}>
        <Statistic style={{backgroundColor: "white", borderRadius: 8, marginRight: "auto", marginLeft: "auto", padding: 8, marginBottom: 16}} suffix={"$"} value={result.sumDogenano* 1.1 * xdgNanoPrice * nanoPrice} title={"Market cap"} precision={0}/>
        </Col>
    </Row>

    </div>
  )
}

export default Stats
